import React, { useState } from "react";
import { Routes } from "../../routes";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/img/logo_shearly.svg";
const Header = (props) => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation("global");
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
      id="mainNav"
    >
      <div className="container px-5">
        <a className="navbar-brand fw-bold" href="#page-top">
          <img
            src={Logo}
            alt="shearly"
            className="img-fluid"
            style={{ height: "22px", verticalAlign: "middle" }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <i className="fa fa-bars"></i>
        </button>
        <div
          className={"collapse navbar-collapse " + (toggle ? "show" : "")}
          id="navbarResponsive"
        >
          <button
            className={"btn  " + (!toggle ? "d-none" : "")}
            type="button"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <i className="fa fa-times"></i>
          </button>
          <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#home">
                {t("home")}
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link me-lg-3" href="#features">
                {t("products")}
              </a>

            </li> */}
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#about">
                {t("about")}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link me-lg-3" href="#contact">
                {t("Contacts")}
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link me-lg-3"
                href="https://doc.shearly.com"
                rel="noreferrer"
                target="_blank"
              >
                {t("documentation")}
              </a>
            </li> */}
          </ul>
          {/* <button
            className="btn rounded-pill px-3 mb-2 mb-lg-0"
            onClick={() => {

              //Redirige al Login
              window.location = Routes.LogIn.path;
            }}
          >
            <span className="d-flex align-items-center">
              <i className="bi-chat-text-fill me-2"></i>
              <span className="small">{t("login")}</span>
            </span>
          </button>
          <button
            className="btn px-3 mb-2 mb-lg-0  btn-primary  rounded-pill"
            onClick={() => {

              //Redirige al Login
              window.location = Routes.Register.path;
            }}
          >
            <span className="d-flex align-items-center">
              <i className="bi-chat-text-fill me-2"></i>
              <span className="small">{t("register")}</span>
            </span>
          </button> */}
        </div>
      </div>
    </nav>
  );
};

export default Header;
