import "./horizontalAccordion.css";
import React, { useEffect, useState } from "react";
import logoOchoa from "../../../assets/img/ochoa.png";
import logoBlueSteel from "../../../assets/img/bluesteel.png";
import logoCodevi from "../../../assets/img/codevi.jpg";
const HorizontalAccordion = (props) => {

	const [elements, setElements] = useState([{
		id: "ochoa",
		cssClass: "ochoa",

		title: "Ochoa",
		content: () => (<div className="row">
			<div className="col col-4">
				<img src="https://ochoa.com.do/images/departamentos/DEP-FERRETERIA.jpg" className="img-thumbnail img-fluid" />
			</div>
			<div className="col col-8">
			<img src={logoOchoa} className="img-fluid" style={{ maxHeight: "100px" }} alt={"ochoa"} />
				<p className="mt-4">Ferretería Ochoa con sus cuatro tiendas por departamentos Avenida Imbert, Avenida Bartolomé Colón, Las Carmelitas y Santo Domingo, dispone de un Centro de Distribución con una capacidad instalada de almacenamiento techado de 90,000 metros cuadrados, y sus Parques Industriales Las Lavas, Las Charcas y Pastor, convirtiéndonos en el suplidor que ofrece mayor variedad y volumen de productos tanto para el canal industrial como el consumidor final, a través de un servicio con calidad y asesoría técnica especializada.</p>
				<a className="btn btn-primary" href={'https://ochoa.com.do'}>Leer más</a>
			</div>
		</div>),
		active: true
	},
	{
		id: "codevi",
		cssClass: "codevi",
		title: "CODEVI Industriel",
		content: () => (<div className="row">
			<div className="col col-4">
				<img src="https://pbs.twimg.com/media/GRBgAJLWUAAG1aK?format=jpg&name=small" className="img-thumbnail img-fluid" />
			</div>
			<div className="col col-8">
			<img src={logoCodevi} className="img-fluid" style={{ maxHeight: "100px" }} alt={"codevi"} />
				<p className="mt-4">+2 pase ven ane nou dedye a ofri pwodwi ak sèvis ak yon filozofi Valè Pataje +2 decades offering products & services with a shared value philosophy.</p>
				<a className="btn btn-primary" href={'https://grupom.com.do'}>Leer más</a>
			</div>
		</div>),
		active: false
	},

	{
		id: "bluesteel",
		cssClass: "bluesteel",
		title: "Blue Steel",
		content: () => (<div>
			
			<div className="row">
				<div className="col col-4">
					<img src="https://bluesteel.do/wp-content/uploads/2023/07/aboutus.png" className="img-thumbnail img-fluid" />
				</div>
				<div className="col col-8">
				<img src={logoBlueSteel} className="img-fluid" style={{ maxHeight: "50px" }} alt={"codevi"} />
					<p className="mt-4">BLUE STEEL S.A.S es una empresa de ingeniería especializada en la fabricación e instalación de estructuras metálicas, brindando nuestros servicios desde nuestras instalaciones de más de 3,600 M2.</p>
					<p>
						Ofrece una gama de servicios que abarcan la fabricación de piezas y estructuras metálicas en general, tuberías de Acero, tanques para almacenamiento de fluidos y otros productos, tanto en acero carbono como inoxidable, garantizando siempre soluciones efectivas a las necesidades especificas de nuestros clientes.</p>
						<a className="btn btn-primary" href={'https://bluesteel.do'}>Leer más</a>
				</div>
			</div>

		</div>), active: false
	},
	{
		id: "4",
		color: "bg-secondary",
		title: "Codevi",
		content: () => (<p>Some quick example text to build on the card title and make up the bulk of the card's content.</p>),
		active: false
	},

	{
		id: "5",
		color: "bg-secondary",
		title: "Blue Steel",
		content: () => (<p>Some quick example text to build on the card title and make up the bulk of the card's content.</p>),
		active: false
	},

	{
		id: "6",
		color: "bg-secondary",
		title: "Blue Steel",
		content: () => (<p>Some quick example text to build on the card title and make up the bulk of the card's content.</p>),
		active: false
	}
	])
	const choiceArray = document.querySelectorAll(".choice")

	useEffect(() => {
		choiceArray.forEach((card) => {
			card.addEventListener("click", () => {
				choiceArray.forEach((element) => {
					element.classList.remove("expand", "unset")
					element.classList.add('small')
				})
				card.classList.remove("small")
				card.classList.add('expand')
			});
		});
	}, [])


	return (<div className="container horizontal-accordion">
		{elements && elements.map((element, index) => (
			<div key={index} className={"clickable card border-none shadow-md choice " + element.cssClass + "  " + (element.active ? "expand" : "small")}
				title={element.title}
				onClick={() => {
					const newElements = elements.map((item) => {
						if (item.id === element.id) {
							item.active = true
						} else {
							item.active = false
						}

						return item

					})
					setElements(newElements)

				}}>
				<div className="card-body">
					{/* <h5 className="card-title">{element.title}</h5> */}
					{element.content()}
					
				</div>
			</div>
		))}

	</div>);
}

export default HorizontalAccordion