import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../css/corporate.css";
import { Routes } from "../../routes";
import syncImg from "../../assets/img/sync.svg";
import productsImg from "../../assets/img/products.svg";
import socialImg from "../../assets/img/social.svg";
import personImg from "../../assets/person01.png";
import { parsedUser } from "../../common/GetCurrentUser";

//Translation
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";

const Invoicing = () => {
  const history = useHistory();
  const user = parsedUser();

  const { t } = useTranslation("global");

  useEffect(() => {
    if (user) {
      history.push(Routes.Timeline.path);
    }
  }, []);
  return (
    <div className="corporate">
      <Header />

      <header className="masthead" id="home">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h1 className="display-1 lh-1 mb-3">Facuración sencilla</h1>
                <p className="lead fw-normal text-muted mb-5">
                  Conoce todas las herramientas para facturar rápido desde donde
                  sea.
                </p>
                <div className="d-flex flex-column flex-lg-row align-items-center">
                  <a className="btn btn-primary" href={'https://auth.shearly.com/Register'}>
                    {t("corporate.shall_begin")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={syncImg}></img>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Quote/testimonial aside--> */}
      <aside className="text-center bg-gradient-primary-to-secondary d-none">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-xl-8">
              <div className="h2 fs-1 text-white mb-4">
                "{t("corporate.manage_office")}"
              </div>
              {/* <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> */}
            </div>
          </div>
        </div>
      </aside>
      {/* <!-- App features section--> */}
      <section id="features" className="bg-gradient-primary-to-secondary">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
              <div className="container-fluid px-5">
                <div className="row gx-5">
                  <div className="col-md-8 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                      <h2 className="font-alt text-light">Facturas</h2>
                      <p className="text-light mb-0">
                        Gestione todo el proceso de facturación en linea, desde
                        creación de tipos de facturas con secuencias
                        individuales hasta la generación de archivos impositivos
                        requeridos <b>607</b> o <b>608</b>. Gestione pagos
                        parciales y completos a facturas con asentamientos a
                        cuentas contables.
                      </p>
                      <p>
                        <button className="btn btn-primary btn-sm mt-2">
                          Ver más
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-0">
              {/* <!-- Features section device mockup--> */}
              <div className="features-device-mockup">
                <img src={productsImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Basic features section--> */}
      <section className="bg-light ">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-5">
              <h2 className="display-4 lh-1 mb-4">
                Dashboard funcional
              </h2>
              <p className="lead fw-normal text-muted mb-5 mb-lg-0">
                Tenga un vistazo rápido de sus operaciones: Clientes por venta, facturación por tipo,
                total facturado por mes, facturas pendientes de cobro al mes. Tenga el control de su situación economica.
              </p>
            </div>
            <div className="col-sm-8 col-md-6">
              <div className="px-5 px-sm-0">
                <img
                  className="img-fluid rounded-circle"
                  src="https://source.unsplash.com/u8Jn2rzYIps/900x900"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Call to action section--> */}
      <section className="cta">
        <div className="cta-content">
          <div className="container px-5">
            <h2 className="text-white display-1 lh-1 mb-4">
              Inicia la experiencia
              <br />
              gratis.
            </h2>
            <a
              className="btn btn-outline-light py-3 px-4 rounded-pill"
              href="https://auth.shearly.com/Register"
              rel="noreferrer"
              target="_blank"
            >
              Empezar
            </a>
          </div>
        </div>
      </section>
      {/* WHY shearly */}
      <section className="bg-gradient-primary-to-secondary" id="about">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="text-white">¿Por qué shearly?</h2>
              <p className="text-white">
                La solución nace de la necesidad interna de Valego Consulting,
                SRL de gestionar nuestra empresa. Si, hay cientos de soluciones
                parecidas pero en ninguna de las que hemos probados hemos podido
                notar que se enfoquen en el flujo 365 de una empresa. Sobre todo
                muchas son muy complejas para cumplir tareas basicas.
              </p>
              <p className="text-white mt-4">
                En Valego Consulting, la empleamos para gestionar TODO:
                facturación, gastos, nominas, comunicación. Entendemos que la
                gestión empresarial debe ser flexible e intuitiva. La creamos
                precisamente para este proposito. Evitar que administrar tú
                negocio ocupe el día completo a todo el equipo.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={socialImg}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- App badge section--> */}
      <section
        className="bg-gradient-primary-to-secondary d-none "
        id="download"
      >
        <div className="container px-5">
          <h2 className="text-center text-white font-alt mb-4">
            {t("corporate.get_app")}!
          </h2>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <a className="me-lg-3 mb-4 mb-lg-0" href="#!">
              <img
                className="app-badge"
                src="assets/img/google-play-badge.svg"
                alt="..."
              />
            </a>
            <a href="#!">
              <img
                className="app-badge"
                src="assets/img/app-store-badge.svg"
                alt="..."
              />
            </a>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
};

export default Invoicing;
